import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import AngleLeft from '@components/Icons/AngleLeft.jsx'
import CloseX from '@components/Icons/Close-X.jsx'
import * as nav from '@data/navdata'

const SubnavToddler = ({
    activeSubnav,
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
    handleToggleSubnav,
    handleCloseMenu,
}) => {
    return (
        <nav
            className={`subnav-wrap w-full h-full bg-[#FFFAF5]`}
            role='navigation'
        >
            <div
                className='mobile-subnav-header flex items-center w-full sticky top-0 bg-[#FFFAF5]'
                data-nav='toddler'
            >
                <div
                    className='subnav-nav-back cursor-pointer p-3 relative left-2 w-[32px] z-10'
                    data-nav='toddler'
                    onClick={handleToggleSubnav}
                >
                    <AngleLeft width='w-[12px]' />
                </div>
                <h2 className='text-center text-2xl flex-1 relative -left-[24px]'>
                    Toddler
                </h2>
                <div
                    onClick={handleCloseMenu}
                    className='mobile-menu-close flex absolute items-center justify-end text-center right-4 top-3'
                >
                    <span className='close-x menu-close relative top-[5px] !font-bold'>
                        <CloseX width='w-[16px]' />
                    </span>
                </div>
            </div>
            <div
                className='menu-section-header flex items-center w-full py-4 border-t border-oat'
                data-subnav='toddler_clothes'
                onClick={handleToggleSubSubnav}
            >
                <span className='menu-section-text pl-6 text-lg'>
                    Shop By Category
                </span>{' '}
                <span className='ml-auto mr-5'>
                    {activeSubSubnav &&
                    activeSubSubnav.toddler_clothes === true ? (
                        <Minus width='w-[16px]' />
                    ) : (
                        <Plus width='w-[18px]' />
                    )}
                </span>
            </div>
            <div
                className={`subsubnav subsubnav-toddler-clothes bg-[#FFFAF5] ${
                    activeSubSubnav && activeSubSubnav.toddler_clothes
                        ? 'block'
                        : 'hidden'
                }`}
                data-subsubnav='toddler_clothes'
            >
                <ul className='nostyle'>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_clothes}>
                            Shop All Toddlers
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_new}>
                            Toddler New Arrivals
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_best_sellers}>
                            Toddler Best Sellers
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_pajamas}>
                            Pajamas
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_bundles}>
                            Outfits + Sets
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_dresses}>
                            Dresses + Rompers
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_shirts}>
                            Shirts + Tops
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_pants}>
                            Pants + Leggings
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_shorts}>
                            Shorts
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_sweaters}>
                            Sweaters + Sweatshirts
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_swimwear}>
                            Swimwear
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_jackets}>
                            Jackets + Outerwear
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toddler_underwear}>
                            Underwear
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toys}>
                            Toys
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a
                            href={nav.toddler_sale}
                            className='text-[#BE202A] block'
                        >
                            Sale
                        </a>
                    </li>
                </ul>
            </div>
            <div
                className='subsubnav subnav-toddler-sizes bg-[#FFFAF5] py-3 border-t border-oat'
                data-subnav='toddler_sizes'
            >
                <div
                    className='menu-section-header flex items-center w-full py-1'
                    data-subnav='toddler_sizes'
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.toddler_sizes === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Size
                    </span>{' '}
                    <span className='ml-auto mr-5'>
                        {activeSubSubnav &&
                        activeSubSubnav.toddler_sizes === true ? (
                            <Minus width='w-[16px]' />
                        ) : (
                            <Plus width='w-[18px]' />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-toddler-sizes ${
                        activeSubSubnav && activeSubSubnav.toddler_sizes
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav='toddler_sizes'
                >
                    <ul className='nostyle'>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.two_t}>
                                2T
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.three_t}>
                                3T
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.four_t}>
                                4T
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.five_t}>
                                5T
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className='subsubnav subnav-toddler-gender bg-[#FFFAF5] py-3 border-t border-oat'
                data-subnav='toddler_gender'
            >
                <div
                    className='menu-section-header flex items-center w-full py-1'
                    data-subnav='toddler_gender'
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.toddler_gender === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Gender
                    </span>{' '}
                    <span className='ml-auto mr-5'>
                        {activeSubSubnav &&
                        activeSubSubnav.toddler_gender === true ? (
                            <Minus width='w-[16px]' />
                        ) : (
                            <Plus width='w-[18px]' />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-toddler-gender ${
                        activeSubSubnav && activeSubSubnav.toddler_gender
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav='toddler_gender'
                >
                    <ul className='nostyle'>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.toddler_boy}>
                                Boy
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.toddler_girl}>
                                Girl
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.toddler_unisex}>
                                Unisex
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className='subsubnav subnav-toddler-moments bg-[#FFFAF5] py-3 border-t border-oat'
                data-subnav='toddler_collections'
            >
                <div
                    className='menu-section-header flex items-center w-full py-1'
                    data-subnav='toddler_collections'
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.toddler_collections === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Collection
                    </span>{' '}
                    <span className='ml-auto mr-5'>
                        {activeSubSubnav &&
                        activeSubSubnav.toddler_collections === true ? (
                            <Minus width='w-[16px]' />
                        ) : (
                            <Plus width='w-[18px]' />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-toddler-moments ${
                        activeSubSubnav && activeSubSubnav.toddler_collections
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav='toddler_collections'
                >
                    <ul className='nostyle'>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.back_to_school}>
                                Back To School
                            </a>
                        </li>

                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.holiday}>
                                Holiday
                            </a>
                        </li>

                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.matching_family}>
                                Matching Family
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className='subsubnav subnav-toddler-moments bg-[#FFFAF5] py-3 border-t border-oat'
                data-subnav='toddler_collaborations'
            >
                <div
                    className='menu-section-header flex items-center w-full py-1'
                    data-subnav='toddler_collaborations'
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.toddler_collaborations === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Collaborations
                    </span>{' '}
                    <span className='ml-auto mr-5'>
                        {activeSubSubnav &&
                        activeSubSubnav.toddler_collaborations === true ? (
                            <Minus width='w-[16px]' />
                        ) : (
                            <Plus width='w-[18px]' />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-toddler-moments ${
                        activeSubSubnav &&
                        activeSubSubnav.toddler_collaborations
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav='toddler_collaborations'
                >
                    <ul className='nostyle'>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.collaborations}>
                                Shop All Collaborations
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.disney}>
                                Shop All Disney
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.toy_story}>
                                Disney Pixar Toy Story
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.disney_princesses}>
                                Disney Princesses
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.moana}>
                                Disney's Moana
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.lion_king}>
                                Disney's The Lion King
                            </a>
                        </li>

                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.winnie_the_pooh}>
                                Disney's Winnie The Pooh
                            </a>
                        </li>

                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.disney_frozen}>
                                Disney's Frozen
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.mickey_minnie}>
                                Disney's Mickey + Minnie Groovy
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.disney_cars}>
                                Disney's Cars
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.eric_carle}>
                                Eric Carle
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.paris_hilton}>
                                Paris Hilton
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.barbie}>
                                Barbie
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavToddler
