import React, { useState, useEffect, Fragment } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useStore } from '@nanostores/react'
import { isMobileMenuOpen } from '@stores/menuStore'
import { userData } from '@stores/userStore'
import { isSearchOpen } from '@stores/searchStore'

import Hamburger from '@components/Icons/Hamburger.jsx'
import CloseX from '@components/Icons/Close-X.jsx'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import AngleRight from '@components/Icons/AngleRight.jsx'
import AngleLeft from '@components/Icons/AngleLeft.jsx'
import DisneyLogo from '@components/Icons/DisneyLogo.jsx'
import Heart from '@components/Icons/Heart.jsx'
import Location from '@components/Icons/Location.jsx'
import User from '@components/Icons/User.jsx'
import UserSolid from '@components/Icons/UserSolid.jsx'
import EmbroideredBlanket from '@components/Icons/EmbroideredBlanket.jsx'
import Calendar from '@components/Icons/Calendar.jsx'
import Document from '@components/Icons/Document.jsx'
import OpenBox from '@components/Icons/OpenBox.jsx'
import Chat from '@components/Icons/Chat.jsx'
import Gift from '@components/Icons/Gift.jsx'
import SubnavMaternity from './SubnavMaternity.jsx'
import SubnavBaby from './SubnavBaby.jsx'
import SubnavNewArrivals from './SubnavNewArrivals.jsx'
import SubnavToddler from './SubnavToddler.jsx'
import SubnavKids from './SubnavKids.jsx'
import SubnavNursery from './SubnavNursery.jsx'
import SubnavGear from './SubnavGear.jsx'
import SubnavGifts from './SubnavGifts.jsx'
import SubnavHelp from './SubnavHelp.jsx'
import SubnavAccount from './SubnavAccount.jsx'
import SearchIcon from '@components/Search/SearchIcon/SearchIcon.jsx'

import * as nav from '@data/navdata'
import SubnavCollaboration from './SubnavCollaboration.jsx'

const NavMobileMenu = () => {
    const $isMobileMenuOpen = useStore(isMobileMenuOpen)
    const $userData = useStore(userData || null)
    const $isSearchOpen = useStore(isSearchOpen || false)

    const [haveUser, setHaveUser] = useState(false)

    useEffect(() => {
        if ($userData && $userData.isLoggedIn) {
            setHaveUser(true)
        } else {
            setHaveUser(false)
        }
    }, [$userData])

    useEffect(() => {
        if ($isMobileMenuOpen) {
            document.body.classList.add('mobile-menu-open')
        } else {
            document.body.classList.remove('mobile-menu-open')
        }
    }, [$isMobileMenuOpen])

    const [activeSubnav, setActiveSubnav] = useState({
        new_arrivals: false,
        maternity: false,
        baby: false,
        toddler: false,
        kids: false,
        nursery: false,
        gear: false,
        gifts: false,
        help: false,
        account: false,
        collaboration: false,
    })

    const [activeSubSubnav, setActiveSubSubnav] = useState({
        more_new_arrivals: false,
        baby_sizes: false,
        baby_gender: false,
        baby_collections: false,
        baby_moments: false,
        baby_clothes: true,
        toddler_sizes: false,
        toddler_gender: false,
        toddler_collections: false,
        toddler_collaborations: false,
        toddler_clothes: true,
        kids_sizes: false,
        kids_gender: false,
        kids_moments: false,
        kids_clothes: true,
        maternity_moments: false,
        gear: false,
        nursery_moments: false,
        gift_bundles: false,
        collaboration_characters: false,
        collaboration_clothes: true,
    })

    const handleMenuToggle = () => {
        isMobileMenuOpen.set(!$isMobileMenuOpen)

        const body = document.querySelector('body')
        const main = document.querySelector('main')
        const menuOpen = isMobileMenuOpen.get()

        if (menuOpen) {
            main.classList.add('menu-overlay')
            body.classList.add('menu-open')

            setActiveSubnav({
                new_arrivals: false,
                maternity: false,
                baby: false,
                kids: false,
                toddler: false,
                nursery: false,
                gear: false,
                gifts: false,
                help: false,
                account: false,
                collaboration: false,
            })

            setActiveSubSubnav({
                more_new_arrivals: false,
                maternity_moments: false,
                maternity_mom: false,
                baby_moments: false,
                baby_nursery: false,
                baby_sizes: false,
                baby_clothes: true,
                toddler_moments: false,
                toddler_sizes: false,
                toddler_clothes: true,
                kids_moments: false,
                kids_sizes: false,
                kids_clothes: true,
                health_safety: false,
                nursery_moments: false,
                furniture_decor: false,
                gear_nursing: false,
                gear_moments: false,
                gift_bundles: false,
                gift_guides: false,
                collaboration_characters: false,
                collaboration_clothes: true,
            })
        } else {
            main.classList.remove('menu-overlay')
            body.classList.remove('menu-open')
        }
    }

    const handleToggleSubnav = (e) => {
        const nav = e.currentTarget.dataset.nav

        console.log('nav', nav, activeSubnav)

        setActiveSubnav({
            ...activeSubnav,
            [nav]: !activeSubnav[nav],
        })

        if (activeSubnav[nav]) {
            isMobileMenuOpen.set(true)
        } else {
            // setTimeout(() => {
            //     isMobileMenuOpen.set(false)
            // }, 100)
            // setActiveSubSubnav({
            //     maternity_moments: false,
            //     maternity_mom: false,
            //     baby_moments: false,
            //     baby_nursery: false,
            //     baby_sizes: false,
            //     baby_clothes: false,
            //     toddler_moments: false,
            //     toddler_sizes: false,
            //     kids_moments: false,
            //     kids_sizes: false,
            //     health_safety: false,
            //     nursery_moments: false,
            //     furniture_decor: false,
            //     gear_nursing: false,
            //     gear_moments: false,
            //     gift_bundles: false,
            //     gift_guides: false,
            //     collaboration_characters: false,
            //     collaboration_clothes: false,
            // })
        }

        if (isSearchOpen.get()) {
            isSearchOpen.set(false)
        }
    }

    const handleCloseMenu = () => {
        const body = document.querySelector('body')
        const main = document.querySelector('main')

        isMobileMenuOpen.set(false)

        setActiveSubnav({
            new_arrivals: false,
            maternity: false,
            baby: false,
            kids: false,
            toddler: false,
            nursery: false,
            gear: false,
            gifts: false,
            help: false,
            account: false,
            collaboration: false,
        })

        setActiveSubSubnav({
            more_new_arrivals: false,
            maternity_moments: false,
            maternity_mom: false,
            baby_moments: false,
            baby_nursery: false,
            baby_sizes: false,
            baby_clothes: false,
            toddler_moments: false,
            toddler_sizes: false,
            kids_moments: false,
            kids_sizes: false,
            health_safety: false,
            nursery_moments: false,
            furniture_decor: false,
            gear_nursing: false,
            gear_moments: false,
            gift_bundles: false,
            gift_guides: false,
            collaboration_characters: false,
            collaboration_clothes: false,
        })

        main.classList.remove('menu-overlay')
        body.classList.remove('menu-open')
    }

    const handleToggleSubSubnav = (e) => {
        const nav = e.currentTarget.dataset.subnav

        console.log('nav', nav, activeSubSubnav)

        setActiveSubSubnav({
            ...activeSubSubnav,
            [nav]: !activeSubSubnav[nav],
        })
    }

    const handleSwiped = (e) => {
        if (e.dir === 'Left') {
            isMobileMenuOpen.set(false)

            const main = document.querySelector('main')
            const menuOpen = isMobileMenuOpen.get()
            main.classList.remove('menu-overlay')
        }
    }

    const menuHandlers = useSwipeable({
        onSwiped: handleSwiped,
        touchEventOptions: { passive: false },
    })

    // console.log('activeSubnav', activeSubnav)

    const toggleSearch = () => {
        isSearchOpen.set(!$isSearchOpen)

        setTimeout(() => {
            const searchInput = document.getElementById('boost-search-input')

            searchInput.focus()
        }, 400)
    }

    useEffect(() => {
        setActiveSubSubnav({
            ...activeSubSubnav,
            baby_clothes: true,
            toddler_clothes: true,
            kids_clothes: true,
        })
    }, [])

    return (
        <div className="mobile-menu-wrap" {...menuHandlers}>
            <div
                className="menu-toggle mr-2 flex-items-center ml-3"
                onClick={handleMenuToggle}
            >
                <span className="screen-reader-text sr-only">Menu</span>
                {$isMobileMenuOpen ? (
                    <span className="close-x menu-close relative top-[1px]">
                        <CloseX width="w-[18px]" />
                    </span>
                ) : (
                    <span className="bars hamburger-menu relative top-[1px] text-[#231F20]">
                        <Hamburger width="w-[18px]" />
                    </span>
                )}

                <span className="times hidden">&times;</span>
            </div>
            <div
                className={`mobile-menu-outer left-0 w-full bg-[#FFFAF5] h-auto overflow-y-auto ${
                    $isMobileMenuOpen ? 'translate-x-0' : '-translate-x-[100%]'
                } fixed top-0 z-10 right-0 ease-in-out duration-500 overflow-x-hidden`}
            >
                <div className="mobile-menu-inner w-full relative flex flex-col items-center">
                    <div className="mobile-menu-header sticky top-0 w-full bg-[#FFFAF5] z-[11]">
                        <div className="mobile-menu-search flex items-center w-full relative py-4 px-6">
                            <input
                                type="text"
                                placeholder="Search"
                                className="h-10 bg-slate/50 rounded-sm border-1 border px-2 w-[calc(100%-48px)]"
                                onClick={toggleSearch}
                            />
                            <div
                                className="search-icon ml-auto absolute cursor-pointer mr-4 left-[calc(100%-96px)]"
                                onClick={toggleSearch}
                            >
                                <SearchIcon width="w-[18px]" />
                            </div>
                        </div>
                        <div
                            onClick={handleMenuToggle}
                            className="mobile-menu-close flex absolute items-center justify-end text-center right-4 top-3"
                        >
                            <span className="close-x menu-close relative top-[1px] !font-bold">
                                <CloseX width="w-[16px]" />
                            </span>
                        </div>
                    </div>
                    <nav className={`mobile-menu w-full  h-full`}>
                        <div className="mobile-menu-sections w-full">
                            <div className="mobile-menu-section mobile-menu-new-arrivals w-full py-3 border-t border-oat">
                                <div
                                    className="menu-section-header flex items-center w-full pl-6 py-2"
                                    data-nav="new_arrivals"
                                    onClick={handleToggleSubnav}
                                >
                                    <span className="menu-section-text ">
                                        New Arrivals
                                    </span>
                                    <span className="ml-auto pr-6">
                                        <AngleRight width="w-[12px]" />
                                    </span>
                                </div>
                            </div>

                            <div className="mobile-menu-section mobile-menu-baby w-full py-3 border-t border-oat relative">
                                <div
                                    className="menu-section-header flex items-center w-full py-2 pl-6"
                                    data-nav="baby"
                                    onClick={handleToggleSubnav}
                                >
                                    <span className="menu-section-text ">
                                        Baby (0–24M)
                                    </span>{' '}
                                    <span className="ml-auto pr-6">
                                        <AngleRight width="w-[12px]" />
                                    </span>
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-toddler w-full py-3 border-t border-oat">
                                <div
                                    className="menu-section-header flex items-center w-full py-2 pl-6"
                                    data-nav="toddler"
                                    onClick={handleToggleSubnav}
                                >
                                    <span className="menu-section-text ">
                                        Toddler (2T–5T)
                                    </span>{' '}
                                    <span className="ml-auto pr-6">
                                        <AngleRight width="w-[12px]" />
                                    </span>
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-kids w-full py-3 border-t border-oat">
                                <div
                                    className="menu-section-header flex items-center w-full py-2 pl-6"
                                    data-nav="kids"
                                    onClick={handleToggleSubnav}
                                >
                                    <span className="menu-section-text">
                                        Kids (6T-14Y)
                                    </span>{' '}
                                    <span className="ml-auto pr-6">
                                        <AngleRight width="w-[12px]" />
                                    </span>
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-maternity w-full py-3 border-t border-oat">
                                <div
                                    className="menu-section-header flex items-center w-full py-2 pl-6"
                                    data-nav="maternity"
                                    onClick={handleToggleSubnav}
                                >
                                    <span className="menu-section-text">
                                        Maternity + Mom
                                    </span>{' '}
                                    <span className="ml-auto pr-6">
                                        <AngleRight width="w-[12px]" />
                                    </span>
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-collaboration w-full py-3 border-t border-oat">
                                <div
                                    className="menu-section-header flex items-center w-full py-2 pl-6"
                                    data-nav="collaboration"
                                    onClick={handleToggleSubnav}
                                >
                                    <span className="menu-section-text">
                                        Collaborations
                                    </span>{' '}
                                    <span className="ml-auto pr-6">
                                        <AngleRight width="w-[12px]" />
                                    </span>
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-nursery w-full py-3 border-t border-oat">
                                <div
                                    className="menu-section-header flex items-center w-full py-2 pl-6"
                                    data-nav="nursery"
                                    onClick={handleToggleSubnav}
                                >
                                    <span className="menu-section-text ">
                                        Nursery + Gear
                                    </span>{' '}
                                    <span className="ml-auto pr-6">
                                        <AngleRight width="w-[12px]" />
                                    </span>
                                </div>
                            </div>

                            <div className="mobile-menu-section mobile-menu-gifts w-full py-3 border-t border-oat">
                                <div
                                    className="menu-section-header flex items-center w-full py-2 pl-6"
                                    data-nav="gifts"
                                    onClick={handleToggleSubnav}
                                >
                                    <span className="menu-section-text ">
                                        Gifts + Bundles
                                    </span>{' '}
                                    <span className="ml-auto pr-6">
                                        <AngleRight width="w-[12px]" />
                                    </span>
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-sale w-full py-3 border-t border-oat">
                                <div
                                    className="menu-section-header flex items-center w-full menu-section-link no-subnav py-2 pl-6"
                                    data-nav="sale"
                                >
                                    <a
                                        href="/collections/sale"
                                        className="flex items-center"
                                    >
                                        <span className="menu-section-text text-rose">
                                            Sale
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className="mobile-menu-bottom bg-slate/70 px-6 border-t border-oat pb-12">
                                {!haveUser ? (
                                    <div className="mobile-menu-section mobile-menu-help w-full py-3">
                                        <div
                                            className="menu-section-header flex items-center w-full py-2"
                                            data-nav="rewards"
                                        >
                                            <a
                                                className="flex items-center"
                                                href="/account/login"
                                            >
                                                <div className="menu-icon w-[18px] flex items-center justify-center">
                                                    <Heart width="w-[15px]" />
                                                </div>
                                                <span className="menu-section-text ml-2">
                                                    Sign In To Get Rewards
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="mobile-menu-link mobile-menu-locations w-full border-t border-oat py-3">
                                    <div
                                        className="menu-section-header flex items-center w-full py-2"
                                        data-nav="locations"
                                    >
                                        <a
                                            className="flex items-center"
                                            href="/collections/embroidery-shop"
                                        >
                                            <div className="menu-icon w-[18px] flex items-center justify-center">
                                                <EmbroideredBlanket width="w-[14px]" />
                                            </div>
                                            <span className="menu-section-text ml-2">
                                                Embroidery Shop
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div className="mobile-menu-link mobile-menu-locations w-full border-t border-oat py-3">
                                    <div
                                        className="menu-section-header flex items-center w-full py-2"
                                        data-nav="locations"
                                    >
                                        <a
                                            className="flex items-center"
                                            href="/pages/guideshop-locations"
                                        >
                                            <div className="menu-icon w-[18px] flex items-center justify-center">
                                                <Location width="w-[14px]" />
                                            </div>
                                            <span className="menu-section-text ml-2">
                                                Visit A Guideshop
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div className="mobile-menu-link mobile-menu-classes-events w-full border-t border-oat py-3">
                                    <div
                                        className="menu-section-header flex items-center w-full py-2"
                                        data-nav="classes-events"
                                    >
                                        <a
                                            className="flex items-center"
                                            href="/pages/classes-and-events"
                                        >
                                            <div className="menu-icon w-[18px] flex items-center justify-center">
                                                <Calendar width="w-[14px]" />
                                            </div>
                                            <span className="menu-section-text ml-2">
                                                Classes + Events
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div className="mobile-menu-link mobile-menu-blog w-full border-t border-oat py-3">
                                    <div
                                        className="menu-section-header flex items-center w-full py-2"
                                        data-nav="blog"
                                    >
                                        <a
                                            className="flex items-center"
                                            href="/blogs/ma-edit"
                                        >
                                            <div className="menu-icon w-[18px] flex items-center justify-center">
                                                <Document width="w-[12px]" />
                                            </div>
                                            <span className="menu-section-text ml-2">
                                                Blog
                                            </span>
                                        </a>
                                    </div>
                                </div>

                                <div className="mobile-menu-section mobile-menu-help w-full border-t border-oat pt-2">
                                    <div
                                        className="menu-section-header flex items-center w-full pt-2"
                                        data-nav="help"
                                    >
                                        <div className="menu-icon w-[18px] flex items-center justify-center">
                                            <Gift width="w-[14px]" />
                                        </div>
                                        <span className="menu-section-text ml-2">
                                            Help + Support
                                        </span>{' '}
                                    </div>
                                    <div
                                        className={`subnav subnav-help block`}
                                        data-subnav="help"
                                    >
                                        <SubnavHelp
                                            activeSubSubnav={activeSubSubnav}
                                            setActiveSubSubnav={
                                                setActiveSubSubnav
                                            }
                                            handleToggleSubSubnav={
                                                handleToggleSubSubnav
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="mobile-menu-section mobile-menu-account w-full border-t border-oat">
                                    <div
                                        className="menu-section-header flex items-center w-full pt-2"
                                        data-nav="account"
                                    >
                                        {haveUser ? (
                                            <div className="menu-icon w-[18px] flex items-center justify-center">
                                                <UserSolid width="w-[14px]" />
                                            </div>
                                        ) : (
                                            <div className="menu-icon w-[18px] flex items-center justify-center">
                                                <User width="w-[14px]" />
                                            </div>
                                        )}
                                        <span className="menu-section-text ml-2">
                                            Account
                                        </span>{' '}
                                    </div>
                                    <div
                                        className={`subnav subnav-account block        : 'hidden'
                                        }`}
                                        data-subnav="account"
                                    >
                                        <SubnavAccount
                                            activeSubSubnav={activeSubSubnav}
                                            setActiveSubSubnav={
                                                setActiveSubSubnav
                                            }
                                            handleToggleSubSubnav={
                                                handleToggleSubSubnav
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="subnavs-outer relative">
                <div className="subnavs-inner">
                    <div
                        className={`subnav subnav-new-arrivals w-full h-full ${
                            activeSubnav && activeSubnav.new_arrivals
                                ? 'translate-x-0'
                                : '-translate-x-[100%]'
                        } fixed top-0 z-200 right-0 ease-in-out duration-500 overflow-x-hidden`}
                        data-subnav="new_arrivals"
                    >
                        <SubnavNewArrivals
                            activeSubnav={activeSubnav}
                            activeSubSubnav={activeSubSubnav}
                            setActiveSubSubnav={setActiveSubSubnav}
                            handleToggleSubSubnav={handleToggleSubSubnav}
                            handleToggleSubnav={handleToggleSubnav}
                            handleCloseMenu={handleCloseMenu}
                        />
                    </div>
                    <div
                        className={`subnav subnav-baby w-full h-full ${
                            activeSubnav && activeSubnav.baby
                                ? 'translate-x-0'
                                : '-translate-x-[100%]'
                        } fixed top-0 z-200 right-0 ease-in-out duration-500 overflow-x-hidden`}
                        data-subnav="baby"
                    >
                        <SubnavBaby
                            activeSubnav={activeSubnav}
                            activeSubSubnav={activeSubSubnav}
                            setActiveSubSubnav={setActiveSubSubnav}
                            handleToggleSubSubnav={handleToggleSubSubnav}
                            handleToggleSubnav={handleToggleSubnav}
                            handleCloseMenu={handleCloseMenu}
                        />
                    </div>
                    <div
                        className={`subnav subnav-toddler w-full h-full ${
                            activeSubnav && activeSubnav.toddler
                                ? 'translate-x-0'
                                : '-translate-x-[100%]'
                        } fixed top-0 z-200 right-0 ease-in-out duration-500 overflow-x-hidden`}
                        data-subnav="toddler"
                    >
                        <SubnavToddler
                            activeSubnav={activeSubnav}
                            activeSubSubnav={activeSubSubnav}
                            setActiveSubSubnav={setActiveSubSubnav}
                            handleToggleSubSubnav={handleToggleSubSubnav}
                            handleToggleSubnav={handleToggleSubnav}
                            handleCloseMenu={handleCloseMenu}
                        />
                    </div>
                    <div
                        className={`subnav subnav-kids w-full h-full ${
                            activeSubnav && activeSubnav.kids
                                ? 'translate-x-0'
                                : '-translate-x-[100%]'
                        } fixed top-0 z-200 right-0 ease-in-out duration-500 overflow-x-hidden`}
                        data-subnav="kids"
                    >
                        <SubnavKids
                            activeSubnav={activeSubnav}
                            handleToggleSubnav={handleToggleSubnav}
                            activeSubSubnav={activeSubSubnav}
                            setActiveSubSubnav={setActiveSubSubnav}
                            handleToggleSubSubnav={handleToggleSubSubnav}
                            handleCloseMenu={handleCloseMenu}
                        />
                    </div>
                    <div
                        className={`subnav subnav-maternity w-full h-full ${
                            activeSubnav && activeSubnav.maternity
                                ? 'translate-x-0'
                                : '-translate-x-[100%]'
                        } fixed top-0 z-200 right-0 ease-in-out duration-500 overflow-x-hidden`}
                        data-subnav="maternity"
                    >
                        <SubnavMaternity
                            activeSubnav={activeSubnav}
                            handleToggleSubnav={handleToggleSubnav}
                            activeSubSubnav={activeSubSubnav}
                            setActiveSubSubnav={setActiveSubSubnav}
                            handleToggleSubSubnav={handleToggleSubSubnav}
                            handleCloseMenu={handleCloseMenu}
                        />
                    </div>
                    <div
                        className={`subnav subnav-collaboration w-full h-full ${
                            activeSubnav && activeSubnav.collaboration
                                ? 'translate-x-0'
                                : '-translate-x-[100%]'
                        } fixed top-0 z-200 right-0 ease-in-out duration-500 overflow-x-hidden`}
                        data-subnav="collaboration"
                    >
                        <SubnavCollaboration
                            activeSubnav={activeSubnav}
                            handleToggleSubnav={handleToggleSubnav}
                            activeSubSubnav={activeSubSubnav}
                            setActiveSubSubnav={setActiveSubSubnav}
                            handleToggleSubSubnav={handleToggleSubSubnav}
                            handleCloseMenu={handleCloseMenu}
                        />
                    </div>
                    <div
                        className={`subnav subnav-nursery w-full h-full ${
                            activeSubnav && activeSubnav.nursery
                                ? 'translate-x-0'
                                : '-translate-x-[100%]'
                        } fixed top-0 z-200 right-0 ease-in-out duration-500 overflow-x-hidden`}
                        data-subnav="nursery"
                    >
                        <SubnavNursery
                            activeSubnav={activeSubnav}
                            handleToggleSubnav={handleToggleSubnav}
                            activeSubSubnav={activeSubSubnav}
                            setActiveSubSubnav={setActiveSubSubnav}
                            handleToggleSubSubnav={handleToggleSubSubnav}
                            handleCloseMenu={handleCloseMenu}
                        />
                    </div>
                    <div
                        className={`subnav subnav-gifts w-full h-full ${
                            activeSubnav && activeSubnav.gifts
                                ? 'translate-x-0'
                                : '-translate-x-[100%]'
                        } fixed top-0 z-200 right-0 ease-in-out duration-500 overflow-x-hidden`}
                        data-subnav="gifts"
                    >
                        <SubnavGifts
                            activeSubnav={activeSubnav}
                            handleToggleSubnav={handleToggleSubnav}
                            activeSubSubnav={activeSubSubnav}
                            setActiveSubSubnav={setActiveSubSubnav}
                            handleToggleSubSubnav={handleToggleSubSubnav}
                            handleCloseMenu={handleCloseMenu}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavMobileMenu
