const Hamburger = ({ width }) => {
    return (
        <div className={`menu-icon ${width} `}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.35 78.27">
                <g fill="currentColor">
                    <path d="M101.35 0H0v7.09h101.35zM101.35 35.59H0v7.09h101.35zM101.35 71.18H0v7.09h101.35z"></path>
                </g>
            </svg>
        </div>
    )
}

export default Hamburger
