import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import Email from '@components/Icons/Email.jsx'
import Phone from '@components/Icons/Phone.jsx'
import Chat from '@components/Icons/Chat.jsx'

import * as nav from '@data/navdata'

const SubnavHelp = ({
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
}) => {
    const handleChat = () => {
        if (typeof GorgiasChat !== 'undefined') {
            GorgiasChat.open()
        }
    }
    return (
        <nav className="subnav-wrap" role="navigation">
            <div className="nav-wrap py-2">
                <ul className="nostyle ">
                    <li className="py-1 text-sm font-normal">
                        <a
                            className="flex items-center"
                            href="mailto:storks@monicaandandy.com"
                        >
                            <span className="icon-wrap w-[18px] flex justify-center items-center">
                                <Email width="w-[14px]" />
                            </span>
                            <span className="text-ocean ml-2">
                                storks@monicaandandy.com
                            </span>
                        </a>
                    </li>
                    <li className="py-1 text-sm font-normal">
                        <a
                            className="flex items-center"
                            href="tel:+17086385584"
                        >
                            <span className="icon-wrap w-[18px] flex justify-center items-center">
                                <Phone width="w-[11px]" />
                            </span>
                            <span className="text-ocean ml-2">
                                (708) 638-5584
                            </span>
                        </a>
                    </li>
                    <li className="py-1 text-sm font-normal">
                        <span
                            className="flex items-center"
                            onClick={() => handleChat()}
                        >
                            <span className="icon-wrap w-[18px] flex justify-center items-center">
                                <Chat width="w-[14px]" />
                            </span>
                            <span className="text-ocean ml-2">Live Chat</span>
                        </span>
                    </li>
                </ul>
            </div>
            <div className="nav-wrap py-2 border-t border-oat">
                <ul className="nostyle">
                    <li className="py-1 text-sm font-normal">
                        <a
                            className="flex items-center"
                            href="/pages/shipping-delivery"
                        >
                            <span className="">Shipping + Delivery</span>
                        </a>
                    </li>
                    <li className="py-1 text-sm font-normal">
                        <a
                            className="flex items-center"
                            href="https://returns.monicaandandy.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="">Returns + Exchanges</span>
                        </a>
                    </li>
                    <li className="py-1 text-sm font-normal">
                        <a className="flex items-center" href="/pages/faq">
                            <span className="">FAQ</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="nav-wrap py-2 border-t border-oat">
                <ul className="nostyle">
                    <li className="py-1 text-sm font-normal">
                        <a
                            className="flex items-center"
                            href="/collections/gift-cards"
                        >
                            <span className="">Gift Cards</span>
                        </a>
                    </li>
                    <li className="py-1 text-sm font-normal">
                        <a
                            className="flex items-center"
                            href="/pages/corporate-gifting"
                        >
                            <span className="">Corporate Gifting</span>
                        </a>
                    </li>
                    <li className="py-1 text-sm font-normal">
                        <a
                            className="flex items-center"
                            href="/pages/our-story"
                        >
                            <span className="">Our Story</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default SubnavHelp
